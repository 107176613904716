<template>
  <div class="wrapper_dialog_admin">
    <div class="relative">
      <transition name="fade" mode="out-in" appear>
        <div class="container">
          <div class="top">
            <span>管理员操作【彩票管理后台】</span>
          </div>
          
          <div class="wallet_items">
            <fieldset>
              <legend>StarLottery 信息 当局id：{{starLotteryId}}</legend>
              <div class="desc_admin">
                <span>本轮奖池的币：{{starAmountCollectedInCake}}</span>
              </div>
            </fieldset>
            <br/>
            <fieldset>
              <legend>ZeedLottery 信息 当局id：{{zeedLotteryId}}</legend>
              <div class="desc_admin">
                <span>本轮奖池的币：{{zeedAmountCollectedInCake}}</span>
              </div>
            </fieldset>
            <br/>
            <fieldset>
              <legend>YeedLottery 信息 当局id：{{yeedLotteryId}}</legend>
              <div class="desc_admin">
                <span>本轮奖池的币：{{yeedAmountCollectedInCake}}</span>
              </div>
            </fieldset>
            <br/>
            <button @click="chooseWalletForMetaMask" :disabled="getWallet" :class="{disabled_btn:getWallet}">连接钱包</button>
            <div class="msg">{{ address ? "连接成功：" + address : "" }}</div>
            
            <br/>
            <fieldset>
              <legend>开始新的一期</legend>
              <!-- <input class="input_style_admin" type="text" placeholder="从现在开始，结束时间"  v-model="startLotteryEndTime">  -->
              <el-date-picker style="width: 100%;"
                v-model="startLotteryEndTime"
                type="datetime"
                placeholder="选择日期时间（从现在开始，结束时间）">
              </el-date-picker>

              <button class="w100" @click="startLottery()" :disabled="startLotteryBtn" :class="{disabled_btn:startLotteryBtn}">开始新的一期</button>
              <div class="msg">最小要离现在四小时，最大是离现在四天。这个字段是一个LONG类型，精确到秒</div>
            </fieldset>
            <br/>
            <fieldset>
              <legend>到了_endTime后需要调用结束该局 </legend>
              <input class="input_style_admin" type="number" placeholder="当局id"  v-model="closeLotteryLotteryId"> 
              <button class="w100" @click="closeLottery()" :disabled="closeLotteryBtn" :class="{disabled_btn:closeLotteryBtn}">结束该局</button>
            </fieldset>
            <br/>
            <fieldset>
              <legend>结束该局后，下一个区块进行开奖（需要closeLottery后的一个区块才能调用） </legend>
              <input class="input_style_admin" type="number" placeholder="closeLottery后的一个区块_lotteryId"  v-model="drawFinalNumberAndMakeLotteryClaimableLotteryId"> 
              <button class="w100" @click="drawFinalNumberAndMakeLotteryClaimable()" :disabled="drawFinalNumberAndMakeLotteryClaimableBtn" :class="{disabled_btn:drawFinalNumberAndMakeLotteryClaimableBtn}">下一个区块进行开奖</button>
            </fieldset>
            <br/>
            <fieldset>
              <legend>查看当前局的id (当局的具体信息需要去不同币种的合约中查看。) </legend>
              <button class="w100" @click="viewCurrentLotteryId()" :disabled="viewCurrentLotteryIdBtn" :class="{disabled_btn:viewCurrentLotteryIdBtn}">查看当前局的id</button>
              <br/>
              <span>_starLotteryId：{{starLotteryId}}</span>
              <br/>
              <span>_zeedLotteryId：{{zeedLotteryId}}</span>
              <br/>
              <span>_yeedLotteryId：{{yeedLotteryId}}</span>
            </fieldset>
            
            
          </div>
          
        </div>
      </transition>
    </div>
  </div>
</template>
<style scoped>
.wrapper_dialog_admin{
  background: #2d3d61;
  padding-bottom: 3rem;
}
.wrapper_dialog_admin .container .top{
  font-size: 2rem;
  padding: 1.6rem;
}
.msg {
  font-size: 1rem;
  color: red;
}
.desc_admin{
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
}
.desc_admin span{
  margin: 1.5rem;
}
.input_style_admin{
  width: 100%;
  display: block;
  padding: 0 1rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.2rem;
  font-weight: 400;
  width: 100%;
  border: 0.1rem solid #4e4d56;
  border-radius: 0.6rem;
  margin-top: 1rem;
  /* color: #E7F3FC; */
  font-family: BebasNeue-Regular;
}
.wrapper_dialog .container .wallet_items button{
  background: url(../../../public/images/box3.png) no-repeat;
  background-size: 100% 100%;
}
.wallet_items a,.wallet_items button{
  font-size: 1.2rem!important;
  border-radius: 0.6rem;
  justify-content: center!important;
}
.w100{
  width: 100%;
  background: url(../../../public/images/box5.png) no-repeat;
  background-size: 100% 100%;
}
</style>
<script>

const mLotteryCenter = require('../../utils/mLotteryCenter')

const mLottery = require('../../utils/mLottery')

export default {
  name: "WalletDialog",
  props: ["id"],
  data() {
    return {
      address:'',

      startLotteryEndTime:'',//从现在开始，结束时间
      closeLotteryLotteryId:'',//当局id
      drawFinalNumberAndMakeLotteryClaimableLotteryId:'',//当局id

      starLotteryId:'',//
      zeedLotteryId:'',//
      yeedLotteryId:'',//

      startLotteryBtn:false,
      closeLotteryBtn:false,
      drawFinalNumberAndMakeLotteryClaimableBtn:false,
      viewCurrentLotteryIdBtn:false,
      getWallet:false,

      starAmountCollectedInCake:'',//
      zeedAmountCollectedInCake:'',//
      yeedAmountCollectedInCake:'',//
      
    };
  },
  created: function () {
    let that = this

    // 默认日期生成
    let nowDate = this.getNowDate()
    that.startLotteryEndTime = nowDate +' 09:00:00'
    var today = new Date();
    var hours = today.getHours();
    // console.log('hours',hours)
    if(hours>=9){
      that.startLotteryEndTime = nowDate +' 21:00:00'
    }
    
      mLotteryCenter.viewCurrentLotteryId().then(res=>{
        that.starLotteryId = res._starLotteryId
        that.zeedLotteryId = res._zeedLotteryId
        that.yeedLotteryId = res._yeedLotteryId

        that.closeLotteryLotteryId = that.starLotteryId
        that.drawFinalNumberAndMakeLotteryClaimableLotteryId = that.starLotteryId

        // 获取奖池的币
        for (let currentCurrency = 0; currentCurrency < 3; currentCurrency++) {
          mLottery.default.switchContract(currentCurrency)
          let contract ={ ...mLottery.default }

          let lotteryId = null;
          switch (currentCurrency) {
            case 0:
              lotteryId = that.starLotteryId
              break;
            case 1:
              lotteryId = that.zeedLotteryId
              break;
            case 2:
              lotteryId = that.yeedLotteryId
              break;
          }
          // 根据lotteryId获得整一局的信息
          contract.viewLottery(lotteryId).then(viewLotteryRes=>{
            let amountCollectedInCake = contract.amountConversion(viewLotteryRes.amountCollectedInCake)
            switch (currentCurrency) {
              case 0:
                that.starAmountCollectedInCake = amountCollectedInCake
                break;
              case 1:
                that.zeedAmountCollectedInCake = amountCollectedInCake
                break;
              case 2:
                that.yeedAmountCollectedInCake = amountCollectedInCake
                break;
            }
          })
          
        }
        
      })
  },
  methods: {    
    getNowDate(){
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      return year + "-" + month + "-" + day
    },

    startLottery(){
      // 开始新的一期
      
      let that = this
      let startLotteryEndTime = that.startLotteryEndTime
      if(!startLotteryEndTime || typeof startLotteryEndTime == "undefined" || startLotteryEndTime == 0){
        this.$toast("请选择日期时间");
        return false
      }
      //时间日期转换时间戳
      var date = new Date(startLotteryEndTime);
      startLotteryEndTime =parseInt( date.getTime() / 1000) + ''
      console.log(that.startLotteryEndTime,startLotteryEndTime)
    
      that.startLotteryBtn = true
      mLotteryCenter.startLottery(startLotteryEndTime).then(res=>{
        console.log(res)
        that.startLotteryBtn = false
      })
    },
    closeLottery(){
      //到了_endTime后需要调用结束该局
      let that = this
      that.closeLotteryBtn = true
      mLotteryCenter.closeLottery(that.closeLotteryLotteryId).then(res=>{
        console.log(res)
        that.closeLotteryBtn = false
      })
    },
    drawFinalNumberAndMakeLotteryClaimable(){
      // 结束该局后，下一个区块进行开奖（需要closeLottery后的一个区块才能调用）
      let that = this
      that.drawFinalNumberAndMakeLotteryClaimableBtn = true
      mLotteryCenter.drawFinalNumberAndMakeLotteryClaimable(that.drawFinalNumberAndMakeLotteryClaimableLotteryId).then(res=>{
        console.log(res)
        that.drawFinalNumberAndMakeLotteryClaimableBtn = false
      })
    },
    viewCurrentLotteryId(){
      // 查看当前局的id (当局的具体信息需要去不同币种的合约中查看。)
      let that = this
      that.viewCurrentLotteryIdBtn = true
      mLotteryCenter.viewCurrentLotteryId().then(res=>{
        that.starLotteryId = res._starLotteryId
        that.zeedLotteryId = res._zeedLotteryId
        that.yeedLotteryId = res._yeedLotteryId
        that.viewCurrentLotteryIdBtn = false


      })
    },


    // 连接钱包 MetaMask
    chooseWalletForMetaMask() {
      var that = this;
      that.getWallet = true;
      if (typeof window.ethereum !== "undefined") {
        const provider = window.ethereum;
        // Stpe1 监听（账号改变、链改变、断开连接）
        provider.on("accountsChanged", (accounts) => {
          // 账号改变
          console.log("账号改变", accounts);
        });
        provider.on("chainChanged", (chainId) => {
          // 链改变
          // window.location.reload()
          console.log("链改变", chainId);
        });
        provider.on("disconnect", (code, reason) => {
          // 断开连接
          console.log("断开连接", code, reason);
        });

        // Stpe2 获取当前钱包账号
       
        provider.request({ method: "eth_requestAccounts" }).then((res) => {
          var address = res[0];
          console.log("当前钱包地址：" + address);
          that.address = address;
          localStorage.setItem('address',address);
          this.$toast(this.$t('menu.extractionSucceeded'));
        });
      } else {
        this.$toast("请安装MetaMask钱包");
      }
      that.getWallet = false;
    },

  },
};
</script>
<style scoped></style>
