import Web3 from "web3";

const web3 = new Web3(window.ethereum);
// let ethers = require("ethers");

// const decimals = 18; // 小数位数

/** STAT V2.0 START  合约MLotteryCenter*/

// 当前网络（主网，测试网）
let defaultChain = process.env.VUE_APP_DEFAULT_CHAIN;

// 主网

let mLotteryCenter = "0xE39326265C462dA11899991Fb9de268BdDE8931F";

// ABI =================================
let MLotteryCenterAbiJson = require('./abi/MLotteryCenter.json');
let MLotteryCenterAbi = MLotteryCenterAbiJson.abi;

// 测试网
if(defaultChain){

  mLotteryCenter = "0xE6F48Ded4A42898cc3bb94af57921D33BB78Bc4b";

  console.log('mLotteryCenter',mLotteryCenter)
}

// 合约
const ContractMLotteryCenter = new web3.eth.Contract(MLotteryCenterAbi, mLotteryCenter); // 合约MLotteryCenter



// 公共方法

// API 

// #1 开奖API 合约MLotteryCenter

/**
 * 开始新的一期
 * uint256 _endTime 从现在开始，结束时间
 */
 export function startLottery(_endTime) {
  const address = localStorage.getItem("address"); //当前用户钱包地址
  console.log('开始新的一期Param',_endTime)
  return new Promise((resolve, reject) => {
    ContractMLotteryCenter.methods.startLottery(_endTime).send({ from: address }, function(err, result){
      console.log("开始新的一期", result);
      resolve(result);
      if (err) reject(err);
    });
  });
}

/**
 * 结束该局
 * 到了_endTime后需要调用结束该局
 * uint256 _lotteryId 当局id
 */
 export function closeLottery(_lotteryId) {
  const address = localStorage.getItem("address"); //当前用户钱包地址
  return new Promise((resolve, reject) => {
    ContractMLotteryCenter.methods.closeLottery(_lotteryId).send({ from: address }, function(err, result){
      console.log("结束该局", result);
      resolve(result);
      if (err) reject(err);
    });
  });
}

/**
 * 结束该局后，下一个区块进行开奖（需要closeLottery后的一个区块才能调用）
 * uint256 _lotteryId 当局id
 */
 export function drawFinalNumberAndMakeLotteryClaimable(_lotteryId) {
  const address = localStorage.getItem("address"); //当前用户钱包地址
  // _lotteryId = ethers.utils.parseUnits(_lotteryId, decimals);
  return new Promise((resolve, reject) => {
    ContractMLotteryCenter.methods.drawFinalNumberAndMakeLotteryClaimable(_lotteryId).send({ from: address }, function(err, result){
      console.log("结束该局后，下一个区块进行开奖", result);
      resolve(result);
      if (err) reject(err);
    });
  });
}

/**
 * 查看当前局的id
 * (当局的具体信息需要去不同币种的合约中查看。)
 */
 export function viewCurrentLotteryId() {
  return new Promise((resolve, reject) => {
    ContractMLotteryCenter.methods.viewCurrentLotteryId().call((err, result) => {
      console.log("查看当前局的id", result);
      resolve(result);
      if (err) reject(err);
    });
  });
}


/** STAT V2.0 END */
